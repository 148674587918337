<template>
  <div class='exceptionTable'>
    <div class="searchForm">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item style="width:150px">
          <el-select v-model="formInline.cityId" clearable placeholder="所在城市">
            <el-option :key="item.cityId" v-for="item in optionsCity" :label="item.cityName" :value="item.cityId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="width:150px">
          <el-select v-model="formInline.companyId" clearable placeholder="所属公司">
            <el-option :key="item.companyId" v-for="item in optionsCompany" :label="item.companyName" :value="item.companyId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button style="width:100px" type="primary" size="medium" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tablebox">
      <div class="table_header">
        <el-button type="warning" v-no-more-click round size="mini" icon="el-icon-download" @click="DownExceptionExcel()">导出</el-button>
      </div>
      <el-table stripe :data="tableData" style="width: 100%" border :header-cell-style="{
                color: '#333',
                fontFamily: 'MicrosoftYaHeiUI',
                fontSize: '14px',
                fontWeight: 900,
                backgroundColor: '#eff3f8',
              }">
        <el-table-column align="center" type="index" label="序" width="50">
          <template slot-scope="scope">
            <span>{{ (pagenum - 1) * size + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="车架号" width="200" prop="carframeNo"></el-table-column>
        <el-table-column align="center" label="车牌" prop="carNumber"></el-table-column>
        <el-table-column align="center" label="公司"> 
          <template slot-scope="scope">
            {{scope.row.cityName}}{{scope.row.companyName}}
          </template>   
        </el-table-column>
        <el-table-column align="center" prop="carType" label="车型"> </el-table-column>
        <el-table-column v-if="exceptionType == '1' " align="center" prop="changeDate" label="变更日期"> </el-table-column>
        <el-table-column v-if="exceptionType == '2' " align="center" prop="businessInsuranceDueDate" label="商业险到期日"> </el-table-column>
        <el-table-column v-if="exceptionType == '2' " align="center" prop="compulsoryInsuranceDueDate" label="交强险到期日"> </el-table-column>
        <el-table-column v-if="exceptionType == '3' " align="center" prop="annuaInspectionDate" label="年检日期"> </el-table-column>
        <el-table-column width="200" align="center" prop="createDate" label="创建时间 "> </el-table-column>
        <el-table-column label="操作" width="220" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="editCarinfo(scope.row.assetsId)">编辑</el-button>
            <el-button size="mini" type="success" icon="el-icon-s-claim" @click="readCar(scope.row.id,scope.row.assetsId)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pagenum" :page-sizes="[1, 5, 10, 20]" :page-size="size"
        layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
    </div>

    <!-- 查看车辆详细信息 -->
    <el-dialog :modal-append-to-body="false" :visible.sync="PreviewVisible" width="55%" @close="PerviewhiddlenDialog">
      <el-divider content-position="left">资产信息</el-divider>
      <el-descriptions class="margin-top" :column="3" size="medium" border>
        <el-descriptions-item>
          <template slot="label">
            所在城市
          </template>
          {{readCarInfo.cityName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            所在公司
          </template>
          {{readCarInfo.companyName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            车架号
          </template>
          {{readCarInfo.carframeNo}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            车辆类型
          </template>
          {{readCarInfo.carType}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            首次上牌年份
          </template>
          {{readCarInfo.firstLicenseYear}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            车品牌国家
          </template>
          {{readCarInfo.countryName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            发动机号
          </template>
          {{readCarInfo.engineNo}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            车牌号
          </template>
          {{readCarInfo.carNumber}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            交强险承保公司
          </template>
          {{readCarInfo.compulsoryInsuranceCompanyName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            交强险到期日
          </template>
          {{readCarInfo.compulsoryInsuranceDueDate}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            商业险承保公司
          </template>
          {{readCarInfo.businessInsuranceCompanyName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            商业险到期日
          </template>
          {{readCarInfo.businessInsuranceDueDate}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            年检时间
          </template>
          {{readCarInfo.annuaInspectionDate}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            出资人
          </template>
          {{readCarInfo.investorName}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            车辆评估价格
          </template>
          {{readCarInfo.evaluatePrice}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            车辆新旧度
          </template>
          {{readCarInfo.newDegree}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            车辆状态
          </template>
          {{readCarInfo.carStatus?optionsStatus[readCarInfo.carStatus-1].label:''}}
        </el-descriptions-item>
        <template v-if="readCarInfo.carStatus==2">
          <el-descriptions-item>
            <template slot="label">
              处置日期
            </template>
            {{readCarInfo.disposedDate}}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus==3">
          <el-descriptions-item>
            <template slot="label">
              拖回日期
            </template>
            {{readCarInfo.dragbackDate}}
          </el-descriptions-item>
          <el-descriptions-item content-class-name="descriptionsNote">
            <template slot="label" :span="2">
              安放点
            </template>
            {{readCarInfo.placementPoint}}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus==4">
          <el-descriptions-item>
            <template slot="label">
              出售日期
            </template>
            {{readCarInfo.saleDate}}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus==5">
          <el-descriptions-item>
            <template slot="label">
              抵押金额
            </template>
            {{readCarInfo.mortgageAmount}}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus==7">
          <el-descriptions-item>
            <template slot="label">
              变更日期
            </template>
            {{readCarInfo.changeDate}}
          </el-descriptions-item>
        </template>
        <template v-if="readCarInfo.carStatus==8">
          <el-descriptions-item>
            <template slot="label">
              结束合同日期(实际)
            </template>
            {{readCarInfo.stopDate}}
          </el-descriptions-item>
        </template>
        <el-descriptions-item>
          <template slot="label">
            实际IRR(%)
          </template>
          {{readCarInfo.realIrr}}
        </el-descriptions-item>

        <el-descriptions-item content-class-name="descriptionsNote" :span="3">
          <template slot="label">
            备注
          </template>
          {{readCarInfo.note}}
        </el-descriptions-item>
      </el-descriptions>
      <el-divider content-position="left">采购信息</el-divider>
      <el-descriptions class="margin-top" :column="2" size="medium" border>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label">
            供应商名称
          </template>
          {{readCarInfo.supplier}}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label">
            签订对象
          </template>
          {{readCarInfo.signingName}}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label">
            采购金额
          </template>
          {{readCarInfo.contractAmount}}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="descriptionspurchase">
          <template slot="label">
            采购时间
          </template>
          {{readCarInfo.signingDate}}
        </el-descriptions-item>
      </el-descriptions>
      <template v-if="leaseInfo.length!=0">
        <el-divider content-position="left">租赁信息</el-divider>
        <el-descriptions class="margin-top" :column="3" size="medium" border v-for="item in leaseInfo" :key="item.id">
          <el-descriptions-item label-class-name="ReadcontractNo">
            <template slot="label">
              合同编号
              <el-link icon='el-icon-info' style="fontSize:10px;margin:0px" type="primary" @click="SkipLese(item.id)">租赁合同详情</el-link>
            </template>
            {{item.contractNo}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              客户
            </template>
            {{item.customer}}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              签订日期
            </template>
            {{item.signDate}}
          </el-descriptions-item>

        </el-descriptions>
      </template>

    </el-dialog>
  </div>
</template>
    
<script>
import { cityList, companyList, FixedAssetsById, leaseData } from "@/api/index.js";
import { getExceptionList, downExceptionList } from "@/api/exceptional.js";
export default {
  name: "",
  props: {
    exceptionType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formInline: {
        companyId: "",
        cityId: "",
        // exceptionType: "",
      },

      optionsCity: [], // 城市列表
      optionsCompany: [], // 公司列表

      tableData: [], // 异常资产列表
      pagenum: 1, // 页码
      size: 10, // 单页数据数
      total: 10, // 数据总数

      PreviewVisible: false,
      readCarInfo: {},
      leaseInfo: [], // 资产相关的合同信息

      optionsStatus: [
        { value: 1, label: "存续" },
        { value: 2, label: "已处置" },
        { value: 3, label: "已拖回" },
        { value: 4, label: "已买断" },
        { value: 5, label: "已抵押" },
        { value: 6, label: "非法过户" },
        { value: 7, label: "待处置" },
        { value: 8, label: "合同结束" },
      ],
    };
  },
  watch: {
    // 监听城市选项是否为空
    "formInline.cityId"(newVal) {
      console.log(newVal);
      if (newVal == "") {
        this.optionsCompany = [];
        this.formInline.companyId = "";
      } else {
        // 获取公司列表
        this.getcompanyList();
        this.formInline.companyId = "";
      }
    },
  },
  activated() {
    console.log("进入异常资产组件");
    this.onSubmit();
  },
  mounted() {
    console.log("表格创建", this.exceptionType);
    let cityList = JSON.parse(window.sessionStorage.getItem("cityList"));
    if (!cityList) {
      this.getcityList();
    } else {
      this.optionsCity = JSON.parse(window.sessionStorage.getItem("cityList"));
    }

    // 切换tab
    this.onSubmit();
  },

  methods: {
    //查询资产列表
    onSubmit() {
      let obj = { ...this.formInline, exceptionType: this.exceptionType };
      console.log(this.formInline, obj);
      getExceptionList(obj, this.pagenum, this.size).then((res) => {
        console.log(res);
        this.tableData = res.data.dataList;
        this.total = res.data.total;
      });
    },
    // 监听pagesize改变的事件

    handleSizeChange(newSize) {
      console.log(newSize);
      this.size = newSize;
      this.pagenum = 1;
      this.onSubmit(); // 发起数据请求
    },
    // 监听页码值改变的事件
    handleCurrentChange(newPage) {
      this.pagenum = newPage;
      this.onSubmit(); // 发起数据请求
    },
    getcityList() {
      cityList().then((res) => {
        this.optionsCity = res.data.dataList;
        window.sessionStorage.setItem("cityList", JSON.stringify(this.optionsCity));
      });
    },
    getcompanyList() {
      companyList(this.formInline.cityId).then((res) => {
        this.optionsCompany = res.data.dataList;
      });
    },

    // 点击编辑按钮--/carinfo页面
    editCarinfo(assetsId) {
      // 同步tag标识
      const MenuItem = { name: "编辑资产信息", url: "/editcarinfo" };
      this.$store.commit("ChangeTags", MenuItem);
      this.$router.push({ name: "editcarinfo", params: { assetsId, exceptionalEdit: true } });
    },

    // 取消查看弹窗
    PerviewhiddlenDialog() {
      this.readCarInfo = {};
      this.leaseInfo = [];
    },
    // 点击查看车辆信息按钮
    readCar(id, assetsId) {
      FixedAssetsById(id).then((res) => {
        const contractAmount = res.data.contractAmount;
        const mortgageAmount = res.data.mortgageAmount;
        res.data.contractAmount = contractAmount != null ? Number(contractAmount).toLocaleString() : "";
        res.data.mortgageAmount = mortgageAmount != null ? Number(mortgageAmount).toLocaleString() : "";
        this.readCarInfo = res.data;
        leaseData({ assetsId }, 1, 100).then((res) => {
          this.PreviewVisible = true;
          this.leaseInfo = res.data.dataList;
        });
      });
    },

    // 查看合同详情
    SkipLese(id) {
      this.$router.push({
        name: "lease",
        params: {
          id,
        },
      });
      this.PreviewVisible = false;
    },

    // 导出异常资产
    DownExceptionExcel() {
      var _this = this;
      let obj = { ...this.formInline, exceptionType: this.exceptionType };
      let excelType = ""; // 文件类型
      switch (this.exceptionType) {
        case '0':
          excelType = "异常资产";
          break;
        case '1':
          excelType = "待处置资产";
          break;
        case '2':
          excelType = "脱产资产";
          break;
        case '3':
          excelType = "未年检资产";
          break;
      }
      downExceptionList(obj)
        .then((res) => {
          console.log(res);
          // 1,创建blob对象
          const blob = new Blob([res], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          const newTime = _this.$moment().format("YYYYMMDDkkmmss");
          const fileName = excelType + newTime; // 文件名

          // 2, 创建类文件对象, 导入blob数据源
          const url = window.URL.createObjectURL(blob);

          // 生成a标签存入body,利用herf属性下载
          const dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", fileName);
          document.body.appendChild(dom);
          dom.click();

          this.$message.success("正在下载...");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
};
</script>
    
<style lang='less' scoped>
</style>