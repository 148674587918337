<template>
  <div id='exceptional'>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item class="myColor1">首页</el-breadcrumb-item>
      <el-breadcrumb-item class="myColor2">异常资产</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div>
        <el-tabs v-model="activeProperty" type="card" @tab-click="handleClick">
          <el-tab-pane label="全部异常资产" name="0">
            <ExceptionalTable v-if="activeProperty==0" :exceptionType="activeProperty" />
          </el-tab-pane>
          <el-tab-pane label="待处置资产" name="1">
            <ExceptionalTable v-if="activeProperty==1" :exceptionType="activeProperty" />
          </el-tab-pane>
          <el-tab-pane label="脱保资产" name="2">
            <ExceptionalTable v-if="activeProperty==2" :exceptionType="activeProperty" />
          </el-tab-pane>
          <el-tab-pane label="未年检资产" name="3">
            <ExceptionalTable v-if="activeProperty==3" :exceptionType="activeProperty" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-card>
  </div>
</template>
    
<script>
import ExceptionalTable from "./commons/exceptionalTable.vue";
export default {
  name: "exceptional",
  components: {
    ExceptionalTable,
  },
  data() {
    return {
      activeProperty: "0", // 0全部异常 1待处置 2脱保 3未年检
    };
  },
  mounted() {
  },
  destroyed() {
    console.log('销毁')
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
  },
};
</script>
    
<style lang='less' scoped>
#exceptional {
  padding: 60px 20px 20px 20px;
}
</style>