import { request } from '@/utils/request'

// 获取异常资产列表
export function getExceptionList(data,pagenum,size) {
    return request({
        url: `/api/manage/fixedAssets/exceptionList/${pagenum}/${size}`,
        method: 'post',
        data
    })
}

// 导出异常资产列表
export function downExceptionList(data) {
    return request({
        url: `/api/manage/fixedAssets/exportExceptionExcel`,
        method: 'post',
        data,
        responseType: 'blob',//服务器返回的数据类型
        timeout: 500 * 1000,
    })
}